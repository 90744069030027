<template>
    <div >
        <el-card shadow="always">
            <el-row>
                <el-col :span="16">
                    <el-form ref="form"  label-width="100px">
                        <el-form-item label="学校名称：">
                            <p>{{classData.schoolName}}</p>
                        </el-form-item>
                        <el-form-item label="班级名称：">
                            <p>{{classData.name}}</p>
                        </el-form-item>
                        <el-form-item label="学号：">
                            <p>{{studentNumber}}</p>
                        </el-form-item>
                        <el-form-item label="任课教师：">
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="8">
                    <el-image v-if="classLogo" style="width:100%;height:180px;" fit="contain" :src="classLogo">
                    </el-image>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="24" >
                    <el-table
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        style="width: 100%;">
                        <el-table-column
                        type="index"
                        width="50">
                        </el-table-column>
                        <el-table-column
                        property="platformCourseName"
                        label="科目"
                        >
                        </el-table-column>
                        <el-table-column
                        property="teacherName"
                        label="教师姓名"
                        >
                        </el-table-column>
<!--                        <el-table-column-->
<!--                        property="teacherAccount"-->
<!--                        label="联系方式">-->
<!--                        </el-table-column>-->
                    </el-table>
                  <div style="margin-top: 22px">
                    <el-pagination
                        class="text_center"
                        background
                        @current-change="queryClassTeacherList"
                        :current-page.sync="classPage"
                        :page-size="pageSize"
                        layout="total,  prev, pager, next, jumper"
                        :total="pageTotal"
                    >
                    </el-pagination>
                  </div>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>
<script>
import {teacherList,schoolInfo,classInfo} from "@/api/schoolClass"
export default {
    name:"classInfo",
    props: {
        loginUser: Object
    },
    data(){
        return{
            tableData: [],
            pageTotal:0,
            pageSize:10,
            classPage:1,
            classData:{},
        }
    },
    created(){
        this.queryClassTeacherList(1);
        this.queryClassInfo();
    },
    components:{

    },
    computed: {
        studentNumber(){
            let sn = this.loginUser.studentNumber;
            if(sn&&sn!=null&&typeof(sn)!='undefined'&&sn!=''){
                return sn;
            }
        },
        classLogo(){
            let classImage = this.classData.logo
            if(classImage&&classImage!=null&&typeof(classImage)!='undefined'&&classImage!=''){
                return classImage;
            }
        }
    },
    updated() {

    },
    methods:{
        queryClassTeacherList(curre){
          this.classPage = curre;
          this.getClassTeacherList()

        },
      getClassTeacherList(){
        teacherList({classId:this.loginUser.classId,page:this.classPage,size:this.pageSize}).then(res=>{
          if(res.code=="0"){
            this.tableData = res.data.records
            this.pageTotal = parseInt(res.data.total)
          }
        })
      },
        queryClassInfo(){
            classInfo({classId:this.loginUser.classId}).then(res=>{
                if(res.code=="0"&&res.data){
                    this.classData = res.data

                }
            })
        }
    }
}
</script>
