<template>
    <div>
        <notJoinClass v-if="loginUser.classData==0" :loginUser="loginUser" @updateUserInfo="updateUserInfo"></notJoinClass>
        <joinClass v-if="loginUser.classData==1" :loginUser="loginUser"></joinClass>
    </div>
</template>
<script>
import notJoinClass from './notJoinClass'
import joinClass from './joinClass.vue'
export default {
    name:"myClass",
    props: {
        loginUser: Object
    },
    data(){
        return{
            
        }
    },
    created(){
        
    },
    components:{
        notJoinClass,
        joinClass
    },
    computed: {

    },
    updated() {
        
    },
    methods:{
        updateUserInfo(userInfo){
            this.$emit('updateUserInfo',userInfo)
        }
    }
}
</script>