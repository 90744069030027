import request from '@/utils/request'

const classApi = {
    classTeacherList:'/schoolClass/courseTeacherList',
    schoolInfo:"/school/oneById",
    classInfo:"/schoolClass/queryById",
    createOrUpdateJournal:"/learnerDiaries/saveOrUpdate",
    journalList:"/learnerDiaries/list",
    
}


/**
 * 查询班级教师列表
 * @param {*} params 
 * @returns 
 */
export function teacherList(params){
    return request({
        url: classApi.classTeacherList,
        method: 'get',
        params:params
    })
}

/**
 * 查询学校信息
 * @param {*} params 
 * @returns 
 */
export function schoolInfo(params){
    return request({
        url: classApi.schoolInfo,
        method: 'get',
        params:params
    })
}

/**
 * 查询班级信息
 * @param {*} params 
 * @returns 
 */
export function classInfo(params){
    return request({
        url: classApi.classInfo,
        method: 'get',
        params:params
    })
}

/**
 * 添加或修改日志
 * @param {*} params 
 * @returns 
 */
export function createOrUpdateJournal(params){
    return request({
        url: classApi.createOrUpdateJournal,
        method: 'POST',
        data:params
    })
}

/**
 * 查询日志
 * @returns 
 */
export function findJournalList(params){
    return request({
        url: classApi.journalList,
        method: 'get',
        params:params
    })
}


