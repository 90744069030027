<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-card shadow="always">
                    <div slot="header" v-if="!isShowCreateOrEdit">
                        <span style="font-weight:bold;font-size:14px">日志列表 ></span>
                        <el-button style="float: right" type="primary" @click="createJournalPage">写日志</el-button>
                    </div>
                    <div slot="header" v-if="isShowCreateOrEdit">
                        <el-button style="font-weight:bold;color:black;font-size:14px" type="text" @click="showJournalListPage">日志列表 ></el-button><span style="font-weight:bold;font-size:14px"> 写日志 ></span>
                    </div>

                    <el-row v-if="!isShowCreateOrEdit">
                        <el-col :span="24">
                            <el-table
                                ref="singleTable"
                                :data="tableData"
                                highlight-current-row
                                style="width: 100%"
                                :show-header="false">
                                <el-table-column
                                property="id"
                                >

                                <template scope="scope">
                                <span style="font-weight:bold">{{!loginUser.name?'学习日志':loginUser.name+"的学习日志"}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                property="createTime"
                                >
                                </el-table-column>
                                <el-table-column>
                                <template slot-scope="scope">
                                    <el-button
                                    size="mini"
                                    type="text" @click="showJournalDetails(scope.row)">查看详情</el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                            <div style="margin-top: 20px">
                              <el-pagination
                                  class="text_center"
                                  background
                                  @current-change="queryJournalList"
                                  :current-page.sync="classPage"
                                  :page-size="pageSize"
                                  layout="total,  prev, pager, next, jumper"
                                  :total="pageTotal"
                              >
                              </el-pagination>
                            </div>

                        </el-col>
                    </el-row>

                    <el-input
                    v-if="isShowCreateOrEdit"
                    type="textarea"
                    :autosize="{ minRows: 10,maxRows: 15}"
                    placeholder="请输入内容"
                    v-model="journalContent">
                    </el-input>
                    <el-row style="margin-top:20px">
                        <el-col :span="12" :offset="6">
                            <el-button style="width:100%" v-if="(createOrEditType=='create'||createOrEditType=='edit')"  type="primary" @click="pushJournal">发送</el-button>
                        </el-col>
                    </el-row>

                </el-card>
            </el-col>
        </el-row>

    </div>
</template>
<script>
import {createOrUpdateJournal,findJournalList} from '@/api/schoolClass'
export default {
    name:"classJournal",
    props: {
        loginUser: Object
    },
    data(){
        return{
            isShowCreateOrEdit:false,
            journalContent:"",
            createOrEditType:"",
            editData:{},
            tableData: [],
            pageTotal:0,
            pageSize:10,
            classPage:1
        }
    },
    created(){
        this.queryJournalList(1);
    },
    components:{

    },
    computed: {

    },
    updated() {

    },
    methods:{
        queryJournalList(curre){
          this.classPage = curre;
          this.getJournalList()
        },
        getJournalList(){
          findJournalList({schoolId:this.loginUser.schoolId,classId:this.loginUser.classId,userId:this.loginUser.id,page:this.classPage,size:this.pageSize}).then(res=>{
            if(res.code=="0"){
              this.tableData = res.data.records
              this.pageTotal = parseInt(res.data.total)
            }
          })
        },
        createJournalPage(){
            this.isShowCreateOrEdit = true;
            this.journalContent = "";
            this.createOrEditType = "create"
        },
        showJournalListPage(){
            this.isShowCreateOrEdit = false;
            this.journalContent = "";
            this.createOrEditType = ""
        },
        pushJournal(){
            if(this.journalContent==null||this.journalContent==''||typeof(this.journalContent)=='undefined'){
                this.$message.error('发布内容错误');
                return;
            }
            if(this.createOrEditType=="create"){
                this.editData.schoolId = this.loginUser.schoolId;
                this.editData.classId = this.loginUser.classId;
                this.editData.mContent = this.journalContent;
            }else{
                this.editData.mContent = this.journalContent;
            }
            createOrUpdateJournal(this.editData).then(res=>{
                if(res.code==0){
                    this.$message({
                    message: '发布成功',
                    type: 'success'
                    });
                    this.isShowCreateOrEdit = false;
                    this.createOrEditType = ""
                    this.editData = {};
                    this.queryJournalList();
                }else{
                    this.$message.error('发布失败');
                }
            }).catch(e=>{
                if(e){
                    this.$message.error('发布失败');
                }
            })

        },
        showJournalDetails(data){
            if(data){
                this.isShowCreateOrEdit = true;
                this.createOrEditType="edit"
                this.editData = data;
                this.journalContent = this.editData.mContent;
            }


        }
    }
}
</script>
