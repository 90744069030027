<template>
    <div class="exercisesListBg">
        <el-row>
            <el-col :span="24">
                <el-card>
                    <div slot="header"  >
                        <el-button type="text" @click="returnPageBtn" v-if="pathRoute!=='answerExam'"> 返回</el-button>
                        <div v-if="openType==2">
                            <el-menu style="width: 100% !important;"  :default-active="selectExercisesType" @select="selectExercisesTypeFunc"  mode="horizontal" >
                                <el-menu-item index="SC">单选题</el-menu-item>
                                <el-menu-item index="MC" >多选题</el-menu-item>
                                <el-menu-item index="TF" >判断题</el-menu-item>
                                <el-menu-item index="FB" >填空题</el-menu-item>
                                <el-menu-item index="SAQ">简答题</el-menu-item>
                                <el-menu-item index="RC" >阅读理解</el-menu-item>
                                <el-menu-item index="RC" v-if="subjectTypeData!==1">综合分析题</el-menu-item>
                                <el-menu-item index="CT" v-if="subjectTypeData==1">完形填空</el-menu-item>
                            </el-menu>
                        </div>
                    </div>
                    <el-table
                        :data="tableData"
                        style="width: 100%"
                        :show-header="false"
                        >
                        <el-table-column >
                            <template slot-scope="scope">
                                <exercisesFB v-if="scope.row[exercisesTypeJudgeFileName]=='FB'" :exercisesInfo="scope.row" :openType="openType" :exercisesListIndex="lineNumberFunc(scope.$index)"
                                :isShowRightAndAnalysis="isShowRightAndAnalysis"
                                :isShowTeacherComments="isShowTeacherComments"
                                :isShowTeacherMCDecideScore="isShowTeacherMCDecideScore"
                                @pushData="receiveSubComData">
                                </exercisesFB>
                                <exercisesMC v-else-if="scope.row[exercisesTypeJudgeFileName]=='MC'" :exercisesInfo="scope.row" :openType="openType" :exercisesListIndex="lineNumberFunc(scope.$index)"
                                :isShowRightAndAnalysis="isShowRightAndAnalysis"
                                :isShowTeacherComments="isShowTeacherComments"
                                :isShowTeacherMCDecideScore="isShowTeacherMCDecideScore"
                                @pushData="receiveSubComData">
                                </exercisesMC>
                                <exercisesSAQ v-else-if="scope.row[exercisesTypeJudgeFileName]=='SAQ'" :exercisesInfo="scope.row" :openType="openType" :exercisesListIndex="lineNumberFunc(scope.$index)"
                                :isShowRightAndAnalysis="isShowRightAndAnalysis"
                                :isShowTeacherComments="isShowTeacherComments"
                                :isShowTeacherMCDecideScore="isShowTeacherMCDecideScore"
                                @pushData="receiveSubComData">
                                </exercisesSAQ>
                                <exercisesSC v-else-if="scope.row[exercisesTypeJudgeFileName]=='SC'" :exercisesInfo="scope.row" :openType="openType" :exercisesListIndex="lineNumberFunc(scope.$index)"
                                :isShowRightAndAnalysis="isShowRightAndAnalysis"
                                :isShowTeacherComments="isShowTeacherComments"
                                :isShowTeacherMCDecideScore="isShowTeacherMCDecideScore"
                                @pushData="receiveSubComData">
                                </exercisesSC>
                                <exercisesTF v-else-if="scope.row[exercisesTypeJudgeFileName]=='TF'" :exercisesInfo="scope.row" :openType="openType" :exercisesListIndex="lineNumberFunc(scope.$index)" :isShowRightAndAnalysis="isShowRightAndAnalysis"
                                :isShowTeacherComments="isShowTeacherComments"
                                :isShowTeacherMCDecideScore="isShowTeacherMCDecideScore"
                                @pushData="receiveSubComData">
                                </exercisesTF>
                                <exercisesCT v-else-if="scope.row[exercisesTypeJudgeFileName]=='RC'||scope.row[exercisesTypeJudgeFileName]=='CT'" :exercisesInfo="scope.row" :openType="openType" :exercisesListIndex="lineNumberFunc(scope.$index)" @pushData="receiveSubComData" ></exercisesCT>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row v-if="showSubmitBtn&&openType==1" style="margin-top:10px">
                        <el-col :span="6" :offset="18">
                            <el-button style="float:right;width:100%;" type="primary" @click="returnDataFunc">提交</el-button>
                        </el-col>
                    </el-row>
<!--                  分页-->
                  <el-pagination
                      background
                      @current-change="updateTableData"
                      :page-size="pageSize"
                      layout="total,  prev, pager, next"
                      :total="dataTotal"
                  >
                  </el-pagination>
                </el-card>
            </el-col>
        </el-row>
        <!-- 考试答题卡内容 -->
        <div :style="{height:examHeight}" v-if="isShowExercisesNotification&&!examTimeShow" class="el-notification right" style=" width:21%;top: 60px; z-index: 2002;">
            <div class="el-notification__content" style="text-align:center;width:100%">
                <div v-if="examData!==null">
                    <p v-if="examData&&examData.examPapersTotalScore"><span style="color:#409EFF">试卷分数: </span>{{examData.examPapersTotalScore}}</p>
                    <p v-if="examData&&examData.exercisesStatistics"><span style="color:#409EFF">试题总数: </span>{{examData.exercisesStatistics.totalExercisesNumber}}</p>
                    <p v-if="examData&&examData.examPapersEffectiveAnswerTime"><span style="color:#409EFF" >答题时间: </span>{{examData.examPapersEffectiveAnswerTime/60}} 分钟</p>
                    <p v-if="examData&&examData.examPapersEffectiveAnswerTime"><span style="color:#409EFF" >剩余时间: </span>{{examPapersCountDown}}</p>
                </div>
                <el-divider></el-divider>
                <div v-if="examData&&examData.exercisesStatistics" style="max-height: 100%;overflow-y: auto">
                    <div style="text-align:left">
                        未做: <el-badge value=" "  type="info"></el-badge> 已做: <el-badge value=" "  type="primary"></el-badge>
                    </div>
                    <div v-if="examData.exercisesStatistics.exercisestypeMapList.SC.length>0" style="padding:10px">
                        <p style="text-align:left;margin-bottom:5px">【单项选择题】</p>
                        <el-row>
                            <el-col :span="4" v-for="(item,index) in examData.exercisesStatistics.exercisestypeMapList.SC">

                                <el-badge :value="index+1"  :type="isShowAnswerSheetStyle('SC',index+1)">

                                </el-badge>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="examData.exercisesStatistics.exercisestypeMapList.MC.length>0" style="padding:10px">
                        <p style="text-align:left;margin-bottom:5px">【多项选择题】</p>
                        <el-row>
                            <el-col :span="4" v-for="(item,index) in examData.exercisesStatistics.exercisestypeMapList.MC">
                                <el-badge :value="index+1"  :type="isShowAnswerSheetStyle('MC',index+1)">

                                </el-badge>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="examData.exercisesStatistics.exercisestypeMapList.TF.length>0" style="padding:10px">
                        <p style="text-align:left;margin-bottom:5px">【判断题】</p>
                        <el-row>
                            <el-col :span="4" v-for="(item,index) in examData.exercisesStatistics.exercisestypeMapList.TF">
                                <el-badge :value="index+1" :type="isShowAnswerSheetStyle('TF',index+1)">

                                </el-badge>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="examData.exercisesStatistics.exercisestypeMapList.FB.length>0" style="padding:10px">
                        <p style="text-align:left;margin-bottom:5px">【填空题】</p>
                        <el-row>
                            <el-col :span="4" v-for="(item,index) in examData.exercisesStatistics.exercisestypeMapList.FB">
                                <el-badge :value="index+1"  :type="isShowAnswerSheetStyle('FB',index+1)">

                                </el-badge>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="examData.exercisesStatistics.exercisestypeMapList.SAQ.length>0" style="padding:10px">
                        <p style="text-align:left;margin-bottom:5px">【简答题】</p>
                        <el-row>
                            <el-col :span="4" v-for="(item,index) in examData.exercisesStatistics.exercisestypeMapList.SAQ">
                                <el-badge :value="index+1"  :type="isShowAnswerSheetStyle('SAQ',index+1)">

                                </el-badge>
                            </el-col>
                        </el-row>
                    </div>
                  <div v-if="examData.exercisesStatistics.exercisestypeMapList.RC.length>0" style="padding:10px">
                    <p style="text-align:left;margin-bottom:5px">【{{pathRoute=='answerExam'?'综合分析题':'阅读理解'}}】</p>
                    <el-row>
                      <el-col :span="4" v-for="(item,index) in examData.exercisesStatistics.exercisestypeMapList.RC">
                        <el-badge :value="index+1"  :type="isShowAnswerSheetStyle('RC',index+1)">

                        </el-badge>
                      </el-col>
                    </el-row>
                  </div>
                  <div v-if="examData.exercisesStatistics.exercisestypeMapList.CT.length>0" style="padding:10px">
                    <p style="text-align:left;margin-bottom:5px">【完形填空】</p>
                    <el-row>
                      <el-col :span="4" v-for="(item,index) in examData.exercisesStatistics.exercisestypeMapList.CT">
                        <el-badge :value="index+1"  :type="isShowAnswerSheetStyle('CT',index+1)">

                        </el-badge>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <el-button @click="returnDataFunc" type="primary" style="width:100%">提交</el-button>
            </div>
        </div>
        <div v-if="openType==2&&!isShowExercisesNotification&&examData.progressType=='AR'" class="el-notification right" style="top: 160px; z-index: 2002;">
            <div class="el-notification__content" style="text-align:left;width:100%">
                <div>
                    <p><span style="color:green">提交时间: </span>{{examData.answerEndTime}}</p>

                    <p><span style="color:green">测试得分: </span>{{examData.totalScore}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import exercisesFB from './exercisesFB.vue'
import exercisesMC from './exercisesMC.vue'
import exercisesSAQ from './exercisesSAQ.vue'
import exercisesSC from './exercisesSC.vue'
import exercisesTF from './exercisesTF.vue'
import exercisesCT from './exercisesCT.vue'
import {formatSeconds} from '@/utils/userCenterUtil'
import {selfCreateOrEditStudyNotes} from "@/api/user";
export default {
    //组件监听事件，queryDataFunc,传递当前页，
    name:"exercisesList",
    props: {
        openType:{
            type:Number,
            required: true//必传，1作业，2考试
        },
        inputData:Object,
        subjectTypeData:'',
        //是否显示答案与解析,默认false
        isShowRightAndAnalysis:{
            type:Boolean,
            default(){
                return false;
            }
        },
        //是否显示教师批语,默认false
        isShowTeacherComments:{
            type:Boolean,
            default(){
                return false;
            }
        },
        isShowTeacherMCDecideScore:{
            type:Boolean,
            default(){
              return false;
            }
        },
        isShowSubmitBtn:{
            type:Boolean,
            default(){
                return false
            }
        },
        //行为类型：1：学生提交作业，2：学生查看已批阅或未批阅作业,
        //3:学生提交试卷,4学生查看已批阅或未批阅试卷,
        //5:习题组卷,6:教师阅卷
        actionType:{
            type:Number,
            default(){
                return 1;
            }
        },
        examData:{
            type:Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return{
            examHeight:0,
            tableData:[],
            pageSize:100,
            dataTotal:0,
            exercisesTypeJudgeFileName:this.openType==1?"workType":"exercisesType",
            showSubmitBtn:false,
            receiveData:{},
            currentPageNum:1,
            selectExercisesType:"SC",
            selectExercisesTypePageCurrentNumber:{},
            exercisesTypeOldTableData:{},
            examPapersCountDownTime:0,
            examPapersCountDown:0,
            nowCutCountDown:1,
            exerciseslineNumberStyle:{
                "SC":{},
                "MC":{},
                "TF":{},
                "FB":{},
                "SAQ":{},
                "RC":{},
                "CT":{},
            },
            setIntervalObj:{},
            startExamPapersSetIntervalObj:false,
            examTimeShow:'',
            pathRoute:''
        }
    },
    created(){
      if(this.$route.query.exam){
        this.examTimeShow=this.$route.query.exam
      }
      if(this.$route.query.examTimeShow){
        this.examTimeShow=this.$route.query.examTimeShow
      }
      this.pathRoute=this.$route.name;
      this.examHeight=(document.documentElement.clientHeight-60)+'px'
    },
    components:{
        exercisesFB,
        exercisesMC,
        exercisesSAQ,
        exercisesSC,
        exercisesTF,
        exercisesCT

    },
  computed: {
        isShowExercisesNotification(){
            let result = false;
            if(this.openType==2&&this.isShowSubmitBtn){
                result = true;
            }
            if(result==true){
                this.startExamPapersSetIntervalObj = true;
                if(this.examData&&this.examData.examPapersEffectiveAnswerTime){
                  this.examPapersCountDownTime = this.examData.examPapersEffectiveAnswerTime;
                }
            }
            return result;
        },
        isShowAnswerSheetStyle(){
            return function(exercisesType,index){
                if(this.exerciseslineNumberStyle[exercisesType][index]==true){
                    return "primary"
                }else{
                    return "info"
                }
            }
        }
    },
    updated() {
      MathJax.typesetPromise();
    },
    methods:{
        updateTableData(curre){
            let etoTableData = this.exercisesTypeOldTableData[this.selectExercisesType];
            if(etoTableData&&etoTableData!=null&&typeof(etoTableData)!='undefined'){
                let oldData = etoTableData[curre];

                if(oldData&&oldData!=null&&typeof(oldData)!='undefined'){
                    this.dataTotal = parseInt(oldData.total);
                    this.pageSize = parseInt(oldData.size);

                    this.tableData = oldData.records;
                    this.currentPageNum = curre;
                    return;
                }
            }

            if(this.openType==1){
                this.$emit('queryDataFunc',curre);
            }
            //如果是试卷，先判断有没有缓存旧数据,如果有则使用缓存数据
            if(this.openType==2){
                this.$emit('queryDataFunc',{curre:curre,exercisesType:this.selectExercisesType});
            }
            this.currentPageNum = curre;
        },
        receiveSubComData(data){
            if(data.exercisesBody){
              let exObj={};
              exObj.exercisesType=data.exercisesType;
              exObj.exercisesListIndex=data.exercisesListIndex
              exObj.id=data.id;
              exObj.exercisesBody=data.exercisesBody;
              this.receiveData[data.id] = exObj;
            }else if(this.receiveData.hasOwnProperty(data.id)){
                this.receiveData[data.id] = Object.assign(this.receiveData[data.id],data);
            }else{
                this.receiveData[data.id] = data;
            }
            let exercisesId = data.id;
            //progressAnswerContent
            let oldData = this.exercisesTypeOldTableData[this.selectExercisesType][this.currentPageNum];
            if(oldData&&oldData!=null&&typeof(oldData)!='undefined'){
                let updateDate = oldData.records;
                updateDate.forEach(element => {
                    if(element.id==exercisesId){
                        element.progressAnswerContent = data.answerContent;
                    }
                });
            }
            let exerciseslineNumber = data.exercisesListIndex;
            let exercisesType = data.exercisesType;
            let newlineNumberStyle = JSON.parse(JSON.stringify(this.exerciseslineNumberStyle));
            let newlineNumber = {};
            let isAnswer = false;
            if(data.exercisesType=='CT'||data.exercisesType=='RC'){
              let bodyPushAnswerContent = [];
              data.exercisesBody.forEach(bodyItem=>{
                if(bodyItem.answerContent){
                  bodyPushAnswerContent.push(bodyItem.answerContent)
                }
                if(bodyItem.mResult){
                  bodyPushAnswerContent.push(bodyItem.answerContent)
                }
                if(bodyPushAnswerContent.length>=data.exercisesBody.length){
                  isAnswer = true;
                }
              });
            }

            let pushAnswerContent = data.answerContent;
            if(Array.isArray(pushAnswerContent)){
                if(pushAnswerContent.length>0){
                    isAnswer = true;
                }
            }else if(pushAnswerContent&&pushAnswerContent!=null&&typeof(pushAnswerContent)!='undefined'){
                isAnswer = true;
            }
            newlineNumber[exerciseslineNumber] = isAnswer
            newlineNumberStyle[exercisesType] = Object.assign(newlineNumberStyle[exercisesType],newlineNumber);
            this.exerciseslineNumberStyle = newlineNumberStyle;
            // this.exerciseslineNumberStyle[exercisesType][exerciseslineNumber] = "success";
        },

        returnDataFunc(){
            this.$confirm('提交后将无法修改, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.$emit('interactiveData',this.receiveData)
                    }).catch(() => {

                    });
        },
        lineNumberFunc(rowIndex){
            return (this.currentPageNum-1)*this.pageSize+1+rowIndex;
        },
        returnPageBtn(){
            clearInterval(this.setIntervalObj);
            this.$emit('callBackFunc')
        },
        selectExercisesTypeFunc(index, indexPath){
            this.selectExercisesType = index;
            let currentNumber = this.selectExercisesTypePageCurrentNumber[index];
            if(!currentNumber||typeof(currentNumber)=='undefined'||currentNumber==null){
                currentNumber = 1;
            }
            this.updateTableData(currentNumber)
        },
        //考试时间倒计时
        startSetIntervalObj(){
            this.setIntervalObj = setInterval(()=>{
                this.examPapersCountDownTime--;
                // let newShowTime = formatSeconds(newEffectiveAnswerTime);
                // this.examPapersCountDown = newShowTime;
                // this.nowCutCountDown++;
            },1000)
        },
    },
    watch:{
        inputData(val, oldVal){//普通的watch监听

            if(val){
              console.log(this.tableData,'this.tableData')
                if(this.openType==1&&val.current&&(val.current==val.pages)&&this.isShowSubmitBtn){
                    this.showSubmitBtn = true;
                }
                // this.exercisesTypeOldTableData[this.selectExercisesType] = {};
                if(!this.exercisesTypeOldTableData[this.selectExercisesType]){
                    this.exercisesTypeOldTableData[this.selectExercisesType] = {};
                }
                this.exercisesTypeOldTableData[this.selectExercisesType][val.current] = val;
                this.selectExercisesTypePageCurrentNumber[this.selectExercisesType] = val.current;
                this.dataTotal = parseInt(val.total);
                this.pageSize = parseInt(val.size);
                this.tableData = val.records;
            }
        },
        examPapersCountDownTime(val, oldVal){
            //等于0自动提交
            if(val==0){
                this.$alert('答题时间已到，已自动交卷', '通知', {
                confirmButtonText: '确定'
                });
                this.$emit('interactiveData',this.receiveData)
                return;
            }
            let newShowTime = formatSeconds(val);
            this.examPapersCountDown = newShowTime;
        },
        startExamPapersSetIntervalObj(val, oldVal){
            if(val==true){
                this.startSetIntervalObj();
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.setIntervalObj);
    },

}
</script>
<style scoped>
@import "../../../public/css/questionsDetail.css";
</style>
