<template>
    <div>
        <el-row v-if="!isShowExamItem">
            <el-col :span="24">
                <el-card shadow="always">
                    <div slot="header" >
                        <el-row>
                            <el-col :span="24">
                                <el-tabs v-model="selectProgressType" @tab-click="handleSelectProgressTypeClick">
                                    <el-tab-pane label="全部" name="all"></el-tab-pane>
                                    <el-tab-pane label="已批阅" name="AR"></el-tab-pane>
                                    <el-tab-pane label="未批阅" name="NR"></el-tab-pane>
                                    <el-tab-pane label="待完成" name="TBC"></el-tab-pane>
                                </el-tabs>
                            </el-col>
                        </el-row>
                    </div>
                        <el-form :inline="true" :model="queryData" class="demo-form-inline">
                        <el-form-item label="考场名称">
                            <el-input v-model="queryData.examPapersTestCenterName" placeholder="考场名称"></el-input>
                        </el-form-item>
                        <el-form-item label="开始时间">
                            <el-date-picker
                            v-model="queryData.examPapersEffectiveStartTime"
                            type="date"
                            placeholder="开始时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="结束时间">
                            <el-date-picker
                            v-model="queryData.examPapersEffectiveEndTime"
                            type="date"
                            placeholder="结束时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="querySchoolClassExamPage(1)">查询</el-button>
                            <el-button type="primary" @click="resetQueryClassExamTerm">重置</el-button>
                        </el-form-item>
                        </el-form>
                        <el-divider></el-divider>
                        <el-table
                            :data="tableData"
                            style="width: 100%"
                            :show-header="false"
                            >
                            <el-table-column>
                            <template slot-scope="scope">
                                <el-card
                                body-style="padding:0px"
                                >
                                <el-row style="padding:0px">
                                    <el-col :span="24">
                                        <el-tag >{{lineNumberFunc(scope.$index)}}</el-tag>
                                        <div v-if="!judgeExamEndTimeIsOver(scope.row.examPapersEffectiveEndTime)" style="float:right;position:relative;">
                                            <div class="triangle-topright"></div>
                                            <div class="toprightFont" style="position:absolute;top:0px;right:10px">进行中</div>
                                        </div>
                                        <div v-else style="float:right;position:relative;">
                                            <div class="triangle-toprightOver"></div>
                                            <div class="toprightFont" style="position:absolute;top:0px;right:10px">已截止</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row style="padding-left:20px">
                                    <el-col :span="16">
                                        <div style="height:100px;">
                                            <ul style="height:100px;flex-direction:row;justify-content:center;">
                                                <li style="list-style-type:none;font-size:18px;font-weight:bold;">{{scope.row.examPapersTestCenterName}}</li>
                                                <li style="list-style-type:none;font-size:15px;color:#999999;">开始时间：{{scope.row.examPapersEffectiveStartTime}}</li>
                                                <li style="list-style-type:none;font-size:15px;color:#999999;">结束时间：{{scope.row.examPapersEffectiveEndTime}}</li>
                                            </ul>

                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div style="height:100px">
                                            <p style="line-height:100px;font-size:15px;font-weight:bold;">{{scope.row.progressType=="TBC"?"待完成":scope.row.progressType=="AR"?"已批阅":"未批阅"}}</p>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <el-button style="background-color:#FFF8F8F8;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)" round @click="showExamItemList(scope.row)">立即查看</el-button>
                                    </el-col>
                                </el-row>
                                </el-card>
                            </template>
                            </el-table-column>
                        </el-table>
                        <div style="margin-top: 20px">
                          <el-pagination
                              class="text_center"
                              background
                              @current-change="querySchoolClassExamPage"
                              :current-page.sync="classPage"
                              :page-size="pageSize"
                              layout="total,  prev, pager, next, jumper"
                              :total="pageTotal"
                          >
                          </el-pagination>
                        </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row v-if="isShowExamItem">
            <el-col :span="24">
                <exercisesList :isShowRightAndAnalysis="isShowRightAndAnalysis" @queryDataFunc="queryExamPapersItem" :openType="2" :inputData="examPapersItemData" :isShowSubmitBtn="isShowSubmitBtn" @interactiveData="examItemSubmitWorkAnswerData"
                @callBackFunc="hideExamItem"
                :isShowTeacherComments="isShowTeacherComments"
                :examData="selectExamData"
                ></exercisesList>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {findExamPapersList,findExamPapersExercisesList,findExamPapersExercisesStatistics,submitSchoolClassTestCenterAnswer} from '@/api/user'
import {hmsFormatDate} from '@/utils/userCenterUtil'
import exercisesList from '@/components/exercisesList/index'
export default {
    name:"classExam",
    props: {
        loginUser: Object
    },
    data(){
        return{
            selectProgressType:"all",
            queryData:{},
            tableData: [],
            pageSize:10,
            pageTotal:0,
            selectExamTestCenterId:"",
            isShowExamItem:false,
            workItemTotal:0,
            examPapersItemSize:100,
            examPapersItemData:{},
            currentPageNum:1,
            isShowRightAndAnalysis:false,
            isShowSubmitBtn:true,
            isShowTeacherComments:false,
            selectExamData:{},
            classPage:1
        }
    },
    created(){
        this.querySchoolClassExamPage(1);
    },
    components:{
        exercisesList
    },
    computed: {

    },
    updated() {

    },
    methods:{
        querySchoolClassExamPage(curee){
          this.classPage = curee;
          this.getSchoolClassExamPage()
        },
        getSchoolClassExamPage(){
          if(this.selectProgressType!="all"){
            this.queryData.progressType = this.selectProgressType;
          }else{
            this.queryData.progressType = "";
          }
          this.currentPageNum = this.classPage;
          this.queryData.page = this.classPage;
          this.queryData.size = this.pageSize;
          this.queryData.testPaperType=0
          findExamPapersList(this.queryData).then(res=>{
            if(res.code=="0"&&res.data){
              this.tableData = res.data.records;
              this.pageTotal = parseInt(res.data.total);
            }
          })
        },
        lineNumberFunc(rowIndex){
            return (this.currentPageNum-1)*this.pageSize+1+rowIndex;
        },
        handleSelectProgressTypeClick(tab, event) {
            this.querySchoolClassExamPage(1);
        },
        judgeExamEndTimeIsOver(endTime){
            let nowTime = new Date().getTime();
            let inTime = endTime.substring(0,19);
            inTime = inTime.replace(/-/g,'/'); //必须把日期'-'转为'/'
            let timestamp = new Date(inTime).getTime();
            return timestamp < nowTime;
        },
        resetQueryClassExamTerm(){
            this.queryData = {};
            this.querySchoolClassExamPage(1);
        },
        showExamItemList(examData){
            this.isShowExamItem = true;
            this.selectExamTestCenterId = examData.examPapersTestCenterId;
            if(examData.progressType!="TBC"||this.judgeExamEndTimeIsOver(examData.examPapersEffectiveEndTime)){
                this.isShowSubmitBtn = false;
                this.isShowRightAndAnalysis = true;
                this.isShowTeacherComments = true;
            }
            //查询试卷习题摘要信息
            findExamPapersExercisesStatistics({userId:this.loginUser.id,examPapersTestCenterId:examData.examPapersTestCenterId}).then(res=>{

                if(res.code=="0"&&res.data){
                    this.selectExamData = examData;
                    this.selectExamData.exercisesStatistics = res.data
                }
            })

            this.queryExamPapersItem({curre:1,exercisesType:"SC"});
        },
        queryExamPapersItem(queryData){
            let curre =  queryData.curre;
            let exercisesType = queryData.exercisesType;
            findExamPapersExercisesList({examPapersTestCenterId:this.selectExamTestCenterId,userId:this.loginUser.id,page:curre,size:this.examPapersItemSize,exercisesType:exercisesType}).then(res=>{
                if(res.code=="0"&&res.data){
                    if(res.data.records.length>0){
                        let newRecords = res.data.records;
                        newRecords.forEach(record=>{
                            let answerVo = {
                                answerFileName:record.answerFileName,
                                answerFileUrl:record.answerFileUrl,
                                mResult:record.mResult,
                                resultScore:record.resultScore,
                                mComments:record.resultEvaluate,
                                answerContent:record.answerContent,
                                errorBody:record.errorBody,
                            };
                            record['answerVo'] = answerVo;
                        })
                    }
                    this.examPapersItemData = res.data;
                }
            });
        },
        examItemSubmitWorkAnswerData(data){
            if(data){
                let pushData = [];
                let nowTime = hmsFormatDate(new Date().getTime());
                for (var key in data){
                    let examItemId = key;
                    let answerContent = data[key].answerContent;
                    pushData.push({
                        id:examItemId,
                        answerContent:answerContent
                    })
                }
                if(pushData.length>0){
                    submitSchoolClassTestCenterAnswer({examPapersTestCenterId:this.selectExamTestCenterId,userAnswerVoList:pushData,submitStartTime:nowTime}).then(res=>{
                        if(res.code=="0"){
                            this.$message({
                            message: '提交成功',
                            type: 'success'
                            });
                            this.querySchoolClassExamPage(1);
                            this.isShowExamItem = false;
                            this.selectExamTestCenterId = "";
                            this.isShowRightAndAnalysis = false;
                            this.isShowTeacherComments = false;
                            this.isShowSubmitBtn = true;
                            this.selectExamData = {};
                        }else{
                            this.$message.error('提交失败');
                        }
                    })
                }
            }
        },
        hideExamItem(){
            if(!this.isShowSubmitBtn){
                this.isShowExamItem = false;
                this.selectExamTestCenterId = "";
                this.isShowRightAndAnalysis = false;
                this.isShowTeacherComments = false;
                this.isShowSubmitBtn = true;
                this.selectExamData = {};
            }else{
                this.$confirm('此操作将删除该所有答题内容是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.isShowExamItem = false;
                    this.selectExamTestCenterId = "";
                    this.isShowRightAndAnalysis = false;
                    this.isShowTeacherComments = false;
                    this.isShowSubmitBtn = true;
                    this.selectExamData = {}
                })
            }
        }
    }
}
</script>

<style scoped>
.triangle-topright {
    width: 0;
    height: 0;
    border-top: 70px solid #DE3638;
    border-left: 70px solid transparent;
}
.triangle-toprightOver {
    width: 0;
    height: 0;
    border-top: 70px solid #409EFF;
    border-left: 70px solid transparent;
}
 .toprightFont{
    color:white;
    width:70px;
    height:70px;
    text-align:center;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    position: absolute;
    filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3)
}
.workTableBodyStyle{
    padding:0px
}
</style>
