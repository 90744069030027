<template>
    <div class="stdclassBg">
        <el-card shadow="always">
            <el-row style="margin-top: 184px">
                <el-col :span="12" :offset="6">
                    <input class="classInput"
                    placeholder="尚未加入班级，请输入班级邀请码"
                    v-model="joinClassCode"
                    clearable>
                    </input>
                </el-col>
            </el-row>
            <el-row style="margin-top:30px">
                <el-col :span="12" :offset="6">
                    <div class="stdClassBtn">
                      <el-button type="primary" @click="joinClass">加入班级</el-button>
                    </div>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>
<script>
import {joinClass} from '@/api/user'
export default {
    name:"notJoinClass",
    props: {
        loginUser: Object
    },
    data(){
        return{
            joinClassCode:"",//班级邀请码
        }
    },
    created(){

    },
    components:{

    },
    computed: {

    },
    updated() {

    },
    methods:{
        joinClass(){
            let joinCode = this.joinClassCode;
            if(!joinCode||typeof(joinCode)=="undefined"||joinCode==null||joinCode.length<=0){
                this.$message.error('班级邀请码错误');
            }
            joinClass({classInviteCode:joinCode}).then(res => {
                if(res.code==0){
                    this.$message({
                    message: '加入成功',
                    type: 'success'
                    });
                    this.$emit('updateUserInfo',res.data)
                }else{
                    this.$message.error('加入失败');
                }
            }).catch(e=>{
                if(e){
                    this.$message.error('加入失败');
                }
            })
        }
    }
}
</script>
<style  scoped>
.joinClassBtn{
    background-image: linear-gradient(to right, #49B151 , #73D27B);
    color: white;
    cursor: pointer;
    border-radius: 8px;
    width:100%;
}
.stdclassBg .el-card{
  min-height: 566px;
}
.classInput{
  border: 1px solid #409EFF;
  padding: 10px;
  width: 95%;
}
.stdClassBtn{
  display: flex;
  justify-content: center;
}
.stdClassBtn .el-button{
  width: 40%;
}
.stdclassBg input::-ms-input-placeholder{text-align: center;font-size: 12px}
.stdclassBg input::-webkit-input-placeholder{text-align: center;font-size: 12px}
</style>
