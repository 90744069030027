<template>
    <div>
        <el-row v-if="!isShowWorkItem">
            <el-col :span="24">
                <el-card shadow="always">
                    <div slot="header" >
                        <el-row>
                            <el-col :span="24">
                                <el-tabs v-model="selectProgressType" @tab-click="handleSelectProgressTypeClick">
                                    <el-tab-pane label="全部" name="all"></el-tab-pane>
                                    <el-tab-pane label="已批阅" name="AR"></el-tab-pane>
                                    <el-tab-pane label="未批阅" name="NR"></el-tab-pane>
                                    <el-tab-pane label="待完成" name="TBC"></el-tab-pane>
                                </el-tabs>
                            </el-col>
                        </el-row>
                    </div>
                        <el-form :inline="true" :model="queryData" class="demo-form-inline">
                        <el-form-item label="课程名称">
                            <el-input v-model="queryData.platformCourseName" placeholder="课程名称"></el-input>
                        </el-form-item>
                        <el-form-item label="开始时间">
                            <el-date-picker
                            v-model="queryData.startTime"
                            type="date"
                            placeholder="开始时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="结束时间">
                            <el-date-picker
                            v-model="queryData.endTime"
                            type="date"
                            placeholder="结束时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="querySchoolClassWorkPage(1)">查询</el-button>
                            <el-button type="primary" @click="resetQueryClassWorkTerm">重置</el-button>
                        </el-form-item>
                        </el-form>
                        <el-divider></el-divider>
                        <el-table
                            :data="tableData"
                            style="width: 100%"
                            :show-header="false"
                            >
                            <el-table-column>
                            <template slot-scope="scope">
                                <el-card
                                body-style="padding:0px"
                                >
                                <el-row style="padding:0px">
                                    <el-col :span="24">
                                        <el-tag >{{lineNumberFunc(scope.$index)}}</el-tag>
                                        <div v-if="!judgeWorkEndTimeIsOver(scope.row.endTime)" style="float:right;position:relative;">
                                            <div class="triangle-topright"></div>
                                            <div class="toprightFont" style="position:absolute;top:0px;right:10px">进行中</div>
                                        </div>
                                        <div v-else style="float:right;position:relative;">
                                            <div class="triangle-toprightOver"></div>
                                            <div class="toprightFont" style="position:absolute;top:0px;right:10px">已截止</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row style="padding-left:20px">
                                    <el-col :span="16">
                                        <div style="height:70px">
                                            <ul style="height:70px;flex-direction:row;justify-content:center;">
                                                <li style="list-style-type:none;font-size:18px;font-weight:bold;margin-bottom: 5px">{{scope.row.name}}</li>
                                                <li style="list-style-type:none;font-size:15px;color:#999999;">结束时间：{{scope.row.endTime}}</li>
                                            </ul>

                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div style="height:70px">
                                            <p style="line-height:70px;font-size:15px;font-weight:bold;">{{scope.row.progressType=="TBC"?"待完成":scope.row.progressType=="AR"?"已批阅":"未批阅"}}</p>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <el-button style="background-color:#FFF8F8F8;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)" round @click="showWorkItemList(scope.row)">立即查看</el-button>
                                    </el-col>
                                </el-row>
                                </el-card>
                            </template>
                            </el-table-column>
                        </el-table>
                        <div style="margin-top: 20px">
                          <el-pagination
                              class="text_center"
                              background
                              @current-change="querySchoolClassWorkPage"
                              :current-page.sync="classPage"
                              :page-size="pageSize"
                              layout="total,  prev, pager, next, jumper"
                              :total="pageTotal"
                          >
                          </el-pagination>
                        </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row v-if="isShowWorkItem">
            <el-col :span="24">
                <exercisesList :isShowRightAndAnalysis="isShowRightAndAnalysis" @queryDataFunc="queryWorkItem" :openType="1" :inputData="workItemData" :isShowSubmitBtn="isShowSubmitBtn" @interactiveData="workItemSubmitWorkAnswerData"
                @callBackFunc="hideWorkItem"
                :isShowTeacherComments="isShowTeacherComments"
                ></exercisesList>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {querySchoolClassWorkList,querySchoolClassWorkItemList,submitSchoolClassWork} from '@/api/user'
import exercisesList from '@/components/exercisesList/index'
export default {
    name:"classWork",
    props: {
        loginUser: Object
    },
    data(){
        return{
            selectProgressType:"all",
            queryData:{},
            tableData: [],
            pageSize:10,
            pageTotal:0,
            selectCourseworkId:"",
            isShowWorkItem:false,
            workItemTotal:0,
            workItemSize:100,
            workItemData:{},
            currentPageNum:1,
            isShowRightAndAnalysis:false,
            isShowSubmitBtn:true,
            isShowTeacherComments:false,
            classPage:1
        }
    },
    created(){
        this.querySchoolClassWorkPage(1);
    },
    components:{
        exercisesList
    },
    computed: {

    },
    updated() {

    },
    methods:{
        querySchoolClassWorkPage(curee){
          this.classPage = curee;
          this.getSchoolClassWorkPage()

        },
        getSchoolClassWorkPage(){
          if(this.selectProgressType!="all"){
            this.queryData.queryStudentProgressType = this.selectProgressType;
          }else{
            this.queryData.queryStudentProgressType = "";
          }
          this.currentPageNum = this.classPage;
          this.queryData.page = this.classPage;
          this.queryData.size = this.pageSize;
          querySchoolClassWorkList(this.queryData).then(res=>{
            if(res.code=="0"&&res.data){
              this.tableData = res.data.records;
              this.pageTotal = parseInt(res.data.total);
            }
          })
        },
        lineNumberFunc(rowIndex){
            return (this.currentPageNum-1)*this.pageSize+1+rowIndex;
        },
        handleSelectProgressTypeClick(tab, event) {
            this.querySchoolClassWorkPage(1);
        },
        judgeWorkEndTimeIsOver(endTime){
            let nowTime = new Date().getTime();
            let inTime = endTime.substring(0,19);
            inTime = inTime.replace(/-/g,'/'); //必须把日期'-'转为'/'
            let timestamp = new Date(inTime).getTime();
            return timestamp < nowTime;
        },
        resetQueryClassWorkTerm(){
            this.queryData = {};
            this.querySchoolClassWorkPage(1);
        },
        showWorkItemList(workData){
            this.isShowWorkItem = true;
            this.selectCourseworkId = workData.id;
            if(workData.progressType!="TBC"||this.judgeWorkEndTimeIsOver(workData.endTime)){
                this.isShowSubmitBtn = false;
                this.isShowRightAndAnalysis = true;
                this.isShowTeacherComments = true;
            }
            this.queryWorkItem(1);
        },
        queryWorkItem(curre){
            querySchoolClassWorkItemList({courseworkId:this.selectCourseworkId,page:curre,size:this.workItemSize}).then(res=>{
                if(res.code=="0"&&res.data){
                    this.workItemData = res.data;
                }
            })
        },
        workItemSubmitWorkAnswerData(data){
            if(data){
                let pushData = [];
                let classId = this.loginUser.classId;
                for (var key in data){
                    let workItemId = key;
                    let answerContent = data[key].answerContent;
                    pushData.push({
                        classId:classId,
                        courseworkId:this.selectCourseworkId,
                        courseworkItemId:workItemId,
                        answerContent:answerContent
                    })
                }
                if(pushData.length>0){
                    submitSchoolClassWork(pushData).then(res=>{
                        if(res.code=="0"){
                            this.$message({
                            message: '提交成功',
                            type: 'success'
                            });
                            this.querySchoolClassWorkPage(1);
                            this.isShowWorkItem = false;
                            this.selectCourseworkId = "";
                            this.isShowRightAndAnalysis = false;
                            this.isShowTeacherComments = false;
                            this.isShowSubmitBtn = true;
                        }else{
                            this.$message.error('提交失败');
                        }
                    })
                }else{
                    this.$message.error('不可提交空答案');
                }
            }
        },
        hideWorkItem(){
            if(!this.isShowSubmitBtn){
                this.isShowWorkItem = false;
                this.selectCourseworkId = "";
                this.isShowRightAndAnalysis = false;
                this.isShowTeacherComments = false;
                this.isShowSubmitBtn = true;
            }else{
                this.$confirm('此操作将删除该所有答题内容是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.isShowWorkItem = false;
                    this.selectCourseworkId = "";
                    this.isShowRightAndAnalysis = false;
                    this.isShowTeacherComments = false;
                    this.isShowSubmitBtn = true;
                })
            }
        }
    }
}
</script>

<style scoped>
.triangle-topright {
    width: 0;
    height: 0;
    border-top: 70px solid #DE3638;
    border-left: 70px solid transparent;
}
.triangle-toprightOver {
    width: 0;
    height: 0;
    border-top: 70px solid #409EFF;
    border-left: 70px solid transparent;
}
 .toprightFont{
    color:white;
    width:70px;
    height:70px;
    text-align:center;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    position: absolute;
    filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3)
}
.workTableBodyStyle{
    padding:0px
}
</style>
