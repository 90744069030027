<template>
    <div class="jionclassBg">
<!--        <userHead></userHead>-->
        <el-card shadow="always" style="margin-top: 20px">
            <el-row>
                <el-col :span="20">
                    <el-menu style="width: 100% !important;" :default-active="defaultJoinClassActiveMenu"  mode="horizontal" @select="selectOpen">
                        <el-menu-item index="1">班级信息</el-menu-item>
                        <el-menu-item index="2">日志</el-menu-item>
                        <el-menu-item index="3">班级作业</el-menu-item>
                        <el-menu-item index="4">班级考试</el-menu-item>
                        <el-menu-item index="5">职教高考</el-menu-item>
                    </el-menu>
                </el-col>
                <el-col :span="4">
                        <!-- <el-button style="float: right;background-color:#DE3638;border-radius: 8px;color:white">考勤签到</el-button> -->
                </el-col>
            </el-row>
        </el-card>

        <el-row style="margin-top:20px">
            <el-col :span="24">
                <component :is="isComponent"  :loginUser="loginUser" />
            </el-col>
            <!-- <el-col :span="6">

            </el-col> -->
        </el-row>

    </div>
</template>
<script>
import userHead from './userHead'
import classInfo from './class/classInfo.vue'
import classJournal from './class/classJournal.vue'
import classWork from './class/classWork.vue'
import classExam from './class/classExam.vue';
import examRehearsal from "../../../views/vocationalExeam/examRehearsal";
export default {
    name:"joinClass",
    props: {
        loginUser: Object
    },
    data(){
        return{
            defaultJoinClassActiveMenu:'1',
            isComponent: '',
        }
    },
    created(){
        this.selectOpen("1");
    },
    components:{
        classInfo,
        classJournal,
        classWork,
        classExam,
        examRehearsal,
        userHead
    },
    computed: {

    },
    updated() {

    },
    methods:{
        selectOpen(index, indexPath){
            let map = {
                '1':'classInfo',  // 班级信息
                '2':'classJournal',  // 日志
                '3':'classWork',  // 班级作业
                '4':'classExam',  // 班级考试
                '5':'examRehearsal',  // 职教高考
            }
            this.isComponent = map[index];
            this.defaultJoinClassActiveMenu = index;
        },
    }
}
</script>
<style>
.jionclassBg .el-tabs__nav-wrap::after{
  background-color: transparent;
}
</style>
